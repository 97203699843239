<template>
    <div class="record">
        <h5header :name="$t('asset.desc135')" :right="false" />
        <div class="record-con">
            <div class="record-info">
                <div class="info-name">{{ $t('asset.desc132') }}</div>
                <p>+{{ info.amount }} {{ info.coinName }}</p>
                <div class="info-img flex">
                    <img src="../../public/images/asset/success.png" alt="" /> {{ $t('assetbag.desc102') }}
                </div>
            </div>
            <div class="record-list">
                <div class="list-info flex">
                    <div>{{ $t('transfer.desc11') }}</div>
                    <p class="flex">{{ dealAddress(info.sn) }} <img src="../../public/images/new/copy.png" alt=""
                            @click="copy($event, info.sn)" /></p>
                </div>
                <div class="list-info flex">
                    <div>{{ $t('transfer.desc12') }}</div>
                    <p>{{ common.formatDate(info.transferTime) }}</p>
                </div>
                <div class="list-info flex">
                    <div>{{ $t('asset.desc127') }}</div>
                    <p>{{ $t('asset.desc134') }}</p>
                </div>

                <div class="list-info flex">
                    <div>{{ $t('asset.desc128') }}</div>
                    <p class="flex">{{ dealAddress1(info.resourceUserId) }} <img src="../../public/images/new/copy.png"
                            alt="" @click="copy($event, info.resourceUserId)" /></p>
                </div>
                <div class="list-info flex">
                    <div>{{ $t('asset.desc129') }}</div>
                    <p class="flex">HYPERCOMUNITY</p>
                </div>
                <!-- <div class="list-info flex">
                    <div>{{ $t('asset.desc130') }}</div>
                    <p class="flex">{{ dealAddress1(info.resourceEmail) }} <img src="../../public/images/new/copy.png"
                            alt="" @click="copy($event, info.resourceEmail)" /></p>
                </div> -->
                <div class="list-info flex">
                    <div>{{ $t('asset.desc131') }}</div>
                    <p>{{ info.coinName }}</p>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
import h5header from '@/componets/h5header.vue';
import Clipboard from '@/utils/libs/clipboard'
export default {
    components: {
        h5header
    },
    data() {
        return {
            info: {}
        }
    },
    mounted() {
        this.info = JSON.parse(localStorage.getItem('recordinfo'))
    },
    methods: {
        dealAddress1(str) {
            if (str && str.length > 12) {
                return str.substring(0, 2) + '....' + str.substring(str.length - 6, str.length)
            } else {
                return str
            }
        },
        copy(e, text) {
            // @click="copy($event,googleRandom.secretKey)"
            let that = this;
            text = text.toString()
            Clipboard(e, text).then((res) => {
                that.$message.success(this.$t('planet.desc61'))
            });
        },
        dealAddress(str) {
            if (str && str.length > 18) {
                return str.substring(0, 6) + '....' + str.substring(str.length - 6, str.length)
            } else {
                return str
            }
        },
    }
}
</script>

<style lang="less" scoped>
.record {
    padding: 0 16px;
    min-height: 100vh;
    background: url('../../public/images/new/h5bg.png') center no-repeat;
    background-size: 100% 100%;

    .record-info {
        margin-bottom: 32px;
        text-align: center;

        .info-name {
            font-size: 14px;
            color: rgba(255, 255, 255, .6);
        }

        p {
            padding: 12px 0;
            font-size: 24px;
            color: #FFFFFF;
        }

        .info-img {
            justify-content: center;
            font-size: 16px;
            color: #fff;
            line-height: 16px;

            img {
                width: 16px;
                height: 16px;
                margin-right: 6px;
            }
        }
    }

    .record-con {
        padding: 56px 0;

        .record-price {
            padding: 24px;
            border-radius: 8px;
            background: #181818;

            .price-left {
                flex: 1;
                font-size: 12px;
                color: rgba(255, 255, 255, .6);
                line-height: 12px;

                div {
                    margin-bottom: 8px;
                    font-size: 24px;
                    color: #FFFFFF;
                    line-height: 24px;
                }
            }

            img {
                flex: 0 0 56px;
                width: 56px;
                height: 56px;
                margin-left: 12px;
            }
        }

        .record-list {
            padding: 0 16px;
            margin-top: 12px;
            border-radius: 8px;
            background: #181818;

            .list-info {
                padding: 16px 0;
                border-bottom: 1px solid rgba(255, 255, 255, 0.06);

                &:last-child {
                    border-bottom: 0;
                }

                div {
                    flex: 0 0 40%;
                    font-size: 13px;
                    color: rgba(255, 255, 255, .6);
                }

                p {
                    flex: 0 0 60%;
                    text-align: right;
                    font-size: 13px;
                    color: #fff;
                    justify-content: flex-end;

                    img {
                        width: 12px;
                        height: 12px;
                        margin-top: 3px;
                        margin-left: 8px;
                    }
                }
            }
        }
    }
}
</style>